<template>
    <div class="login-page">
      <LoginForm @submit-login="handleLogin" />
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script>
  import LoginForm from '../components/LoginForm.vue';
  import { apiLoginUser } from '../api/apiService';
  import eventBus from '@/eventBus';
  
  export default {
    components: {
      LoginForm,
    },
    data() {
      return {
        errorMessage: null,
      };
    },
    methods: {
      async handleLogin(credentials) {

        try {

          console.log('Handle Login');

          const response = await apiLoginUser(credentials.email, credentials.password);

          const { token, user_id, firstname, surname } = response;
          
            
          // Store the token in localStorage
          localStorage.setItem('authToken', token);
          localStorage.setItem('userId', user_id);
          localStorage.setItem('firstname', firstname);
          localStorage.setItem('surname', surname);
  
          // Redirect to TaskPage upon successful login
          this.$router.push({ name: 'TaskPage' });
          eventBus.emit('show-sticky-note', 'Du bist nun eingeloggt' );
        } catch (error) {
          this.errorMessage = 'Login failed. Please check your credentials and try again.';
          console.error('Login error:', error);
        }
      },

    },
  };
  </script>