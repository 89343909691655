<template>
  <div class="task-scanner">
    <!-- QR Scanner Section -->      
        <div class="qr-scanner">
          <qrcode-stream @decode="onDecode" @detect="onDetect" :track="paintBoundingBox"></qrcode-stream>
          <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </div>
      <!-- Manual Task Search -->
      <div class="manual-search">
        <TaskSearch @task-selected="handleTaskSelected" />
      </div>
  </div>
</template>

<script>
import { QrcodeStream} from 'vue-qrcode-reader';
import TaskSearch from './TaskSearch.vue'


export default {
  components: {
    QrcodeStream,
    TaskSearch
  },
  data() {
    return {
      taskId: '', // Holds the task number from input or QR scan
      errorMessage: '',
    };
  },
  methods: {

    async onDetect (promise) {
      try {
        const result = await promise[0];
        const taskId = result.rawValue;
        
        this.$emit('task-scanned', taskId);
        
      } catch (error) {
        console.log('detect error: ' + error);
      }
    },

    async onInit(promise){
      console.log(promise);

    },    
    async onDecode (decodedString) {      
      try {
        console.log('Code: '+decodedString);
      } catch (error) {
        console.log('decode error: ' + error);
      }
    },

    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 3
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },
    loadTask() {

      console.log('Load task: ' + this.taskId);

      if (this.taskId) {
        // Emit the task number to the parent component
        this.$emit('task-scanned', this.taskId);
        
      }
    },

    handleTaskSelected(taskId) {
      // Handle the task number in the parent component
      console.log("Task selected:", taskId);
      // You can trigger any main view functionality here
      this.$emit('task-scanned', taskId);
    }
  },
};
</script>

<style scoped>

.task-scanner {
  width: 100%;
}

.qr-scanner {
  width: 80%; /* or a fixed width */
  height: 200px; /* Fixing height to prevent layout shift */
  display: flex;
  justify-content: center;
  align-items: center;
}


.manual-search {
  display: flex;
  flex-direction: row; /* Aligns input and button in a row */
  align-items: center;
  justify-content: center; /* Centers the row within the container */
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%; /* Full width of the container */
  gap: 5px;

}



.error {
  color: red;
  font-weight: bold;
}
</style>
