<template>
    <div class="profile-page">
      <UserProfile />
    </div>
  </template>
  
  <script>
  import UserProfile from '../components/UserProfile.vue';
  export default {
    components: { UserProfile },
  };
  </script>
  