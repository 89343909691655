<template>
  <div class="login-container">
    <img src="@/assets/logo_full_450.png" alt="Logo" class="logo" />
    <form @submit.prevent="submitLogin" class="login-form">
      <div class="form-group">
        <label for="email">E-Mail:</label>
        <input 
          type="text" 
          id="email" 
          v-model="email" 
          class="input-field"
          @blur="validateEmail" 
        />
        <p v-if="emailError" class="error-message">{{ emailError }}</p>
      </div>
      <div class="form-group">
        <label for="password">Passwort:</label>
        <input 
          type="password" 
          id="password" 
          v-model="password" 
          class="input-field" 
          @blur="validatePassword" 
        />
        <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
      </div>
      <button type="submit" class="login-button">ANMELDEN</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
    };
  },
  methods: {
    validateEmail() {
      if (!this.email) {
        this.emailError = 'Bitte E-Mail ausfüllen';
      } else if (!/\S+@\S+\.\S+/.test(this.email)) {
        this.emailError = 'Bitte korrekte E-Mail eintragen';
      } else {
        this.emailError = '';
      }
    },
    validatePassword() {
      if (!this.password) {
        this.passwordError = 'Bitte Passwort ausfüllen';
      } else {
        this.passwordError = '';
      }
    },
    submitLogin() {
      this.validateEmail();
      this.validatePassword();
      // Emit the login event only if there are no validation errors
      if (!this.emailError && !this.passwordError) {

        console.log('VALID');

        this.$emit('submit-login', { email: this.email, password: this.password });
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.logo {
  width: 250px;
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}
</style>
