<!-- src/components/AppHeader.vue -->
<template>
  <header class="app-header">
    <nav class="nav-container">
      <RouterLink to="/auftraege" class="nav-item">
        <i class="icon">📝</i>
        <span>Aufträge</span>
      </RouterLink>
      <RouterLink to="/profile" class="nav-item">
        <i class="icon">👤</i>
        <span>Profile</span>
      </RouterLink>
    </nav>
  </header>
</template>

<script>
export default {};
</script>

<script setup>
import { RouterLink } from 'vue-router';

</script>

<style scoped>
.app-header {
  background-color: var(--primary-color);
  padding: 10px;
}

.nav-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 15px;
  font-size: 12px;
}

.icon {
  font-size: 22px;
  margin-right: 5px;
}

.nav-item:hover {
  color: var(--accent-color);
}
</style>
