<template>
  <div id="app">
 <AppHeader />
    <router-view />
    <StickyNote />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import StickyNote from './components/StickyNote.vue';

export default {
  components: {
    AppHeader,
    StickyNote,
  },
  mounted() {
    this.loadFeedbucketScript();
  },
  methods: {
    loadFeedbucketScript() {
      (function(k) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.module = true;
        s.defer = true;
        s.src = 'https://cdn.feedbucket.app/assets/feedbucket.js';
        s.dataset.feedbucket = k;
        document.head.appendChild(s);
      })('xBG389DfU0XXbVeGyC4E');
    },
  },
};
</script>


<style>

body {
  background-color: var(--background-color);
  margin: 0;
  color: var(--secondary-color);
}

h1, h2, h3, p, button, input {
  font-family: var(--font-family);
}

#app {
  
}
</style>
