// src/apiService.js
import axios from 'axios';


const baseURL = process.env.VUE_APP_API_URL || 'http://localhost:3000';

console.log(process.env.VUE_APP_API_URL);
// Set up Axios with the base URL
const api = axios.create({
    baseURL: baseURL, // Your local API base URL
    //baseURL: 'https://amboss-appbackend-ei5p3.kinsta.app/v1', // Your local API base URL
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
  // Function to get a task by ID
  export const getTaskById = async (taskId) => {
    try {
      const response = await api.get(`/v1/task/${taskId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching task with ID ${taskId}:`, error);
      throw error;
    }
  };

    // Function to get a task by ID
  export const getTaskList = async () => {
    try {
      const response = await api.get(`/v1/getTaskList/`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching all tasks:`, error);
      throw error;
    }
  };


// Example of a POST request with data
export const apiUpdateTaskStatus = async (taskNumber, taskStatus) => {
  try {
    const response = await api.post('/v1/update-task', {
      task_nr: taskNumber,
      task_status: taskStatus,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update task status:', error);
    throw error;
  }
};

export const apiLoginUser = async (email, password) => {
  try {
    const response = await api.post('/v1/login', {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error('API login error:', error);
    throw error;
  }
};


export const saveTrackedTime = async (trackingData) => {
  
  console.log('API Call to save time', trackingData);

  try { 
    const response = await api.post('/v1/saveTrackedTime', trackingData);
    return response.data;
  } catch (error) {
    console.error('Error saving tracked time:', error);
    throw error;
  }
};


export const getTrackedTimes = async (userId, date) => {
  try {
    const response = await api.get(`/v1/getTrackedTimes`, {
      params: {
        user_id: userId,
        date: date,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tracked times:', error);
    throw error;
  }
};

export const saveVacation = async (vacationData) => {
  try {
    const response = await api.post('/v1/addAbsence', vacationData);
    return response.data;
  } catch (error) {
    console.error('Error saving vacation:', error);
    throw error;
  }
};

export const getAbsences = async (userId) => {
  try {
    const response = await api.get(`/v1/getAbsences`, {
      params: { user_id: userId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching absences:', error);
    throw error;
  }
};

export const deleteAbsence = async (absenceId) => {
  try {
    const response = await api.delete(`/v1/deleteAbsence`, {
      data: { absence_id: absenceId },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting absence:', error);
    throw error;
  }
};

export const fotoUpload = async (formData) => {
  try {
    const response = await api.post('/v1/fotoUpload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading photo:', error);
    throw error;
  }
};