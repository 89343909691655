<template>
    <div v-if="visible" class="sticky-note"    >
      <span>{{ message }}</span>
    </div>
  </template>
  
  <script>
  import eventBus from '../eventBus';
  
  export default {
    data() {
      return {
        message: '', // Message to display
        visible: false, // Controls visibility
        timeoutId: null, // Store timeout ID to clear if needed
      };
    },
    methods: {
      showMessage(text) {
        // If there's an active timeout, clear it to reset the timer
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
  
        // Set the new message and make it visible
        this.message = text;
        this.visible = true;
  
        // Set a new timeout to hide the sticky note after 2 seconds
        this.timeoutId = setTimeout(() => {
          this.visible = false;
          this.timeoutId = null; // Reset timeout ID
        }, 2000);
      },
    },
    mounted() {
      // Listen for the 'show-sticky-note' event from the event bus
      eventBus.on('show-sticky-note', this.showMessage);
    },
    beforeUnmount() {
      // Remove the event listener and clear any remaining timeout
      eventBus.off('show-sticky-note', this.showMessage);
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
    },
  };
  </script>
  
  <style scoped>
  .sticky-note {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    z-index: 9999; /* Ensure it's on top of all other content */
    text-align: center;
  }
  </style>
  