<template>
  <div v-if="localTask && localTask.data" class="task-item">
    <p class="task-number"><strong>{{ localTask.data.task_number }}</strong></p>
    <p class="task-name">{{ localTask.data.task_name }}</p>
    
    <!-- Display Task Status as a Button-Like Box -->
    <div class="task-status">
      {{ localTask.data.task_status }}
    </div>

    <!-- Title for Status Change Section -->
    <p class="status-title">Wechsle Status zu:</p>

    <!-- Status Navigation -->
    <div class="status-navigation">
      <button @click="previousStatus" :disabled="!previousStatusLabel">
        ← {{ previousStatusLabel }}
      </button>
      <button @click="nextStatus" :disabled="!nextStatusLabel">
        {{ nextStatusLabel }} →
      </button>
    </div>

    <div id="foto_upload">
       <!-- Foto Hochladen Button -->
       <button @click="triggerFileInput" class="upload-button">Foto hochladen</button>
       <input type="file" ref="fileInput" accept="image/*" capture="environment" @change="handleFileUpload" style="display: none;" />
     </div>

  </div>
  <!-- Fallback message if task data is missing -->
  <p v-else>Kein Auftrag geladen.</p>
</template>

<script>

import { fotoUpload } from '../api/apiService';
import eventBus from '@/eventBus';

export default {
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localTask: { ...this.task }, // Local copy of the task to avoid direct prop mutation
    };
  },
  watch: {
    task: {
      handler(newTask) {
        this.localTask = { ...newTask }; // Update localTask if task prop changes
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentIndex() {
      return this.localTask.data.status_values.indexOf(this.localTask.data.task_status);
    },
    previousStatusLabel() {
      return this.currentIndex > 0
        ? this.localTask.data.status_values[this.currentIndex - 1]
        : null;
    },
    nextStatusLabel() {
      return this.currentIndex < this.localTask.data.status_values.length - 1
        ? this.localTask.data.status_values[this.currentIndex + 1]
        : null;
    },
  },
  methods: {
    previousStatus() {
      if (this.currentIndex > 0) {
        const newStatus = this.localTask.data.status_values[this.currentIndex - 1];
        this.updateTaskStatus(newStatus);
      }
    },
    nextStatus() {
      if (this.currentIndex < this.localTask.data.status_values.length - 1) {
        const newStatus = this.localTask.data.status_values[this.currentIndex + 1];
        this.updateTaskStatus(newStatus);
      }
    },
    updateTaskStatus(newStatus) {
      // Update localTask's status and emit the change to the parent component
      this.localTask.data.task_status = newStatus;
      this.$emit('update-status', newStatus);
    },
    triggerFileInput() {
      this.$refs.fileInput.click(); // Open file input dialog
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('task_id', this.localTask.data.task_number);
        try {
          await fotoUpload(formData); // Use fotoUpload from apiService
          eventBus.emit('show-sticky-note', 'Foto erfolgreich hochgeladen.' );
          console.log('Foto erfolgreich hochgeladen');
        } catch (error) {
          console.error('Error uploading photo:', error);
          console.log('Fehler beim Hochladen des Fotos');
        }
      }
    },
  },
};
</script>

<style scoped>
.task-item {
  padding: 10px;
  text-align: center;
}

.task-number, .task-name {
  font-size: 18px;
  margin: 5px 0;
}

.task-name {
  margin-top: 10px;
}

.task-status {
  background-color: var(--accent-color);
  padding: 10px 25px;
  border-radius: 15px;
  display: inline-block;
  margin-top: 15px;
  font-size: 16px;
  width: 175px;
}

.status-title {
  font-size: 12px;
  margin-top: 20px;
}

.status-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 25px;
  gap: 15px;
}

.status-navigation button {
  background-color: #e0e0e0;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: normal;
  cursor: pointer;
  font-size: 14px;
}

.status-navigation button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.upload-button {
  margin-top: 15px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  border: none;
}
</style>
