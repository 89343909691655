<template>
  <div class="user-profile">
    <h2 class="user-name">Hoi, <span>{{ firstname }}</span></h2>

    <p class="random-message">{{ randomMessage }}</p>

    <div class="tracked-time">
      Heute getracked: <span class="time">{{ totalTrackedTime }}</span>
    </div>

    <!-- Date Picker for selecting tracked date -->
    <div class="date-picker">
      <p>Alte Daten laden</p>
      <div class="date-input-container">
        <input type="date" id="tracked-date" v-model="selectedDate" @change="fetchTrackedTime" aria-label="Datum wählen" />
      </div>
    </div>

    <!-- Divider -->
    <hr class="divider" />

    <!-- Title below the divider -->
    <h3 class="absence-title">Abwesenheiten:</h3>

      <ul class="absence-list">
        <li v-for="absence in absences" :key="absence.id" class="absence-item">
          <span>{{ formatDate(absence.start_date) }} - {{ formatDate(absence.end_date) }} ({{ absence.absence_type }})</span>
          <span class="delete-icon" @click="handleDeleteAbsence(absence.absence_id)">🗑️</span>
        </li>
      </ul>
    
    <!-- Toggle Button for Abwesenheit Form -->
    <button @click="toggleAbsenceForm" class="toggle-button">
      {{ absenceFormVisible ? 'Schliessen' : 'Abwesenheit eintragen' }}
    </button>

    <!-- Abwesenheit Form - Shown when absenceFormVisible is true -->
    <div v-if="absenceFormVisible" class="absence-form">
      <label for="absence-type">Abwesenheitsart:</label>
      <select id="absence-type" v-model="absenceType" class="absence-select">
        <option disabled value="">Bitte wählen</option>
        <option>Ferien</option>
        <option>Krankheit</option>
        <option>Ausbildung</option>
        <option>Firmenevent</option>
      </select>

      <label for="start-date">Startdatum:</label>
      <input type="date" id="start-date" v-model="startDate" />

      <label for="end-date">Enddatum:</label>
      <input type="date" id="end-date" v-model="endDate" />

      <button @click="submitVacation" class="submit-button">Eintragen</button>
    </div>

    <!-- Logout Button -->
    <button @click="logout" class="logout-button">Logout</button>
  </div>
</template>

<script>
import { getTrackedTimes, saveVacation, getAbsences, deleteAbsence } from '../api/apiService';
import eventBus from '@/eventBus';

export default {
  data() {
    return {
      firstname: localStorage.getItem('firstname'), // Retrieve firstname from localStorage
      userId: localStorage.getItem('userId'), // Retrieve user_id from localStorage
      totalTrackedTime: '0h 0m 0s', // Default tracked time display
      selectedDate: new Date().toISOString().split('T')[0], // Default to today's date
      randomMessage: '',
      absenceType: '', // Selected absence type
      startDate: '', // Start date of absence
      endDate: '', // End date of absence
      absenceFormVisible: false,
      absences: [],
    };
  },
  mounted() {
    // Fetch tracked time data for the current date when component is mounted
    this.fetchTrackedTime();
    this.fetchAbsences();
    this.selectRandomMessage();
  },
  methods: {
    toggleAbsenceForm() {
      this.absenceFormVisible = !this.absenceFormVisible;
    },
    async fetchTrackedTime() {
      try {
        const response = await getTrackedTimes(localStorage.getItem('userId'), this.selectedDate);

        console.log(response);
        
        // Assuming response contains total tracked time in the format "Xh Ym Zs"
        this.totalTrackedTime = response.total_tracked_time || '0h 0m 0s';
      } catch (error) {
        console.error('Failed to fetch tracked time:', error);
        this.totalTrackedTime = 'N/A'; // Display N/A if there’s an error
      }
    },
    selectRandomMessage() {
      const mitarbeiterBotschaften = [
        "Du machst einen großartigen Job – danke für deinen Einsatz!",
        "Mit deiner Energie und Begeisterung bringst du das Team voran!",
        "Danke für deinen unermüdlichen Einsatz – du machst den Unterschied!",
        "Deine Ideen und Kreativität inspirieren uns alle!",
        "Ohne dich wäre das Team nicht komplett – danke, dass du dabei bist!",
        "Deine harte Arbeit und Sorgfalt sind ein echtes Vorbild!",
        "Mit dir an unserer Seite können wir alles schaffen!",
        "Danke, dass du immer den Extra-Schritt gehst – das ist Gold wert!",
        "Deine positive Einstellung steckt uns alle an!",
        "Du bringst frischen Wind ins Team und das tut uns gut!",
        "Deine Professionalität und dein Engagement sind beeindruckend!",
        "Mit deiner Zuverlässigkeit kann man auf dich zählen – danke dafür!",
        "Deine Kollegialität macht das Arbeitsumfeld so viel besser!",
        "Danke, dass du immer hilfsbereit und freundlich bist!",
        "Du machst das Arbeiten zu einem echten Vergnügen!",
      ];
      // Select a random message
      const randomIndex = Math.floor(Math.random() * mitarbeiterBotschaften.length);
      this.randomMessage = mitarbeiterBotschaften[randomIndex];
    },
    async submitVacation() {
      if (!this.absenceType || !this.startDate || !this.endDate) {
        alert('Bitte füllen Sie alle Felder aus');
        return;
      }

      const vacationData = {
        user_id: localStorage.getItem('userId'),
        absence_type: this.absenceType,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      try {
        await saveVacation(vacationData);
        // Reset form fields after successful submission
        this.absenceType = '';
        this.startDate = '';
        this.endDate = '';
        eventBus.emit('show-sticky-note', 'Abwesenheit ist eingetragen.' );
        this.absenceFormVisible = false;

            // Refresh absences
        await this.fetchAbsences();
        this.absences = [...this.absences];

      } catch (error) {
        console.error('Failed to save vacation:', error);
        eventBus.emit('show-sticky-note', 'Abwesenheit konntte nicht eingetragen werden.' );
      }
    },
    async fetchAbsences() {
      try {
        const response = await getAbsences(localStorage.getItem('userId'));

        console.log(response);

        this.absences = response;
      } catch (error) {
        console.error('Failed to fetch absences:', error);
      }
    },
    async handleDeleteAbsence(absenceId) {
      if (confirm('Möchten Sie diese Abwesenheit wirklich löschen?')) {
        try {
          await deleteAbsence(absenceId);
          
          this.absences = this.absences.filter(absence => absence.absence_id !== absenceId);
          this.absences = [...this.absences]; // Force reactivity update


          eventBus.emit('show-sticky-note', 'Abwesenheit wurde gelöscht.' );
      
        } catch (error) {
          console.error('Failed to delete absence:', error);
          alert('Fehler beim Löschen der Abwesenheit');
        }
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    logout() {
      // Clear authentication details from localStorage
      localStorage.removeItem('authToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('firstname');
      localStorage.removeItem('surname');
      
      // Redirect to the Login page
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style scoped>
.user-profile {
  padding: 20px;
  position: relative;
  height: 100vh;
}

.user-name {
  text-align: left;
  font-size: 24px;
  margin-bottom: 10px;
}

.user-name span{
  font-style: italic; 
  color:  var(--button-color);
}


.random-message {
  font-size: 11px;
  color: #555;
  text-align: left;
  font-style: italic; 
  margin-bottom: 20px;
}

.tracked-time {
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
  text-align: center;
}

.tracked-time .time {
  font-weight: bold;
  font-size: 16px;
}

.date-picker {
  margin-bottom: 20px;
  text-align: left;
}

.date-picker p {
  font-size: 12px;
  margin-right: 10px;
}

.absence-list {
  list-style: none;
  padding: 0;
}

.absence-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.delete-icon {
  cursor: pointer;
  color: #f44336;
}


.toggle-button {
  margin-top: 10px;
  padding: 5px 15px;
  border: 0;
  background-color: var(--primary-color);
}



.logout-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #f44336;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f;
}

 /* General Reset for Date Field */
 .date-input-container {
  margin-top: 4px;
  position: relative;
  display: inline-block;
  width: 150px;
}

/* Style for Date Input */
.date-input-container input[type="date"] {
  width: 100%;
  padding: 5px 12px;
  padding-left: 40px; /* Space for icon */
  font-size: 12px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  appearance: none; /* Remove native date styles */
  -webkit-appearance: none;
  outline: none;
  transition: border-color 0.2s ease;
}

.date-input-container input[type="date"]:focus {
  border-color: #007bff;
}

/* Calendar Icon */
.date-input-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 12px;
  width: 20px;
  height: 20px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3Crect x="3" y="4" width="18" height="18" rx="2" ry="2"%3E%3C/rect%3E%3Cline x1="16" y1="2" x2="16" y2="6"%3E%3C/line%3E%3Cline x1="8" y1="2" x2="8" y2="6"%3E%3C/line%3E%3Cline x1="3" y1="10" x2="21" y2="10"%3E%3C/line%3E%3C/svg%3E');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  pointer-events: none;
}

.divider {
  border: none;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

.absence-title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
}

.absence-form label {
  display: block;
  font-size: 14px;
  margin-top: 10px;
}

.absence-select,
input[type="date"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: var(--button-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--button-color);
}


</style>
