<template>
  <div class="task-page">
    <!-- Accordion Button to Show/Hide TaskScanner -->
    <button id="scanner-toggle-btn" @click="toggleScanner">
      {{ scannerVisible ? 'Schliessen' : 'Scanner' }}
    </button>
     <!-- TaskScanner Component with conditional spacing when open -->
     <div :style="{ marginBottom: scannerVisible ? `${scannerHeight}px` : '0' }">
      <TaskScanner v-if="scannerVisible" @scanner-height="updateScannerHeight" @task-scanned="fetchTask" />
    </div>

    <!-- Other Components -->
    <SingleTask :task="task" @update-status="updateTaskStatus" />
    <TimeTracking @save-time="saveElapsedTime" />
  </div>
</template>

<script>
import TaskScanner from '../components/TaskScanner.vue';
import SingleTask from '../components/SingleTask.vue';
import TimeTracking from '../components/TimeTracking.vue';
import { getTaskById, apiUpdateTaskStatus, saveTrackedTime } from '../api/apiService';
import taskStatusList from '../data/taskStatusList.json';
import eventBus from '@/eventBus';

export default {
  components: {
    TaskScanner,
    SingleTask,
    TimeTracking,
  },
  data() {
    return {
      scannerVisible: false, // Accordion state
      task: null,
      taskId: '',
      updateScannerHeight: '',
      scannerHeight: '',
      isUpdating: false,
      pendingStatus: null,     // Holds the latest status if changes occur during update
      debounceTimer: null,
    };
  },
  created() {
    this.loadLastTask(); // Load the last task when the component is created
  },
  methods: {
    async fetchTask(taskId) {

      console.log('fetsch task: ', taskId);
      
      try {
        const response = await getTaskById(taskId); // Fetch task data by ID and update state

    // Map `task_status_list` number to the corresponding status list
        const statusListEntry = taskStatusList.list.find(
          entry => entry.number === parseInt(response.data.task_status_list)
        );

        console.log(statusListEntry.values);

        // Assign the mapped values to `status_values` in `task.data`
        this.task = {
          ...response,
          data: {
            ...response.data,
            status_values: statusListEntry ? statusListEntry.values : [], // Add the status list values
          },
        };

        //console.log('Task data: ');
        //console.log(this.task);

        this.saveTaskToLocalStorage();

        eventBus.emit('show-sticky-note', 'Auftrag: ' + taskId +' gefunden' );
        this.scannerVisible = false;

      } catch (error) {
        console.error('Failed to fetch task:', error);
        eventBus.emit('show-sticky-note', 'Auftrag: ' + taskId +' konnte nicht geladen werden' );
      }
    },

    toggleScanner() {
      this.scannerVisible = !this.scannerVisible;
    },

    saveTaskToLocalStorage() {
      if (this.task) {
        localStorage.setItem('lastTask', JSON.stringify(this.task));
      }
    },
    loadLastTask() {
      const savedTask = localStorage.getItem('lastTask');
      if (savedTask) {
        this.task = JSON.parse(savedTask);
      }
    },

    updateTaskStatus(newStatus) {
    // Update the task status immediately in the UI
    this.task.data = {
      ...this.task.data,
      task_status: newStatus,
    };

    // Save the updated status in local storage for frontend persistence
    this.saveTaskToLocalStorage();

    console.log(newStatus);

    // Clear any existing debounce timer and set a new one
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Set the debounce timer with a delay of 500ms
    this.debounceTimer = setTimeout(() => {
      // If an update is already in progress, store the latest status and return
      if (this.isUpdating) {
        this.pendingStatus = newStatus;
        return;
      }

      // Begin the update process
      this.executeStatusUpdate(newStatus);
    }, 1000);
  },

  async executeStatusUpdate(newStatus) {
    // Set the update flag and reset the pending status
    this.isUpdating = true;
    this.pendingStatus = null;

    console.log('Start status update');

    try {
      // Perform the API call to update the status
      await apiUpdateTaskStatus(this.task.data.task_number, newStatus);

      // Check if a new status change was queued during the update
      if (this.pendingStatus !== null) {
        // Use the latest pending status for the next update
        const nextStatus = this.pendingStatus;
        this.pendingStatus = null;
        this.executeStatusUpdate(nextStatus);
      } else {
        // No pending updates, clear the update flag
        this.isUpdating = false;
      }

      eventBus.emit('show-sticky-note', 'Status wechsel gespeichert.' );

    } catch (error) {
      console.error('Failed to update task status:', error);
      eventBus.emit('show-sticky-note', 'Bitte nochmals versuchen.' );
      this.isUpdating = false; // Reset the update flag on failure
    }
  },

  async saveElapsedTime(elapsedTime) {
      // Prepare the tracking data structure

      const hours = Math.floor(elapsedTime / 3600);
      const minutes = Math.floor((elapsedTime % 3600) / 60);
      const seconds = elapsedTime % 60;
      const formattedTime = `${hours}h ${minutes}m ${seconds}s`;

      const trackingData = {
        "task_number": this.task.data.task_number,
        "data" : {
          "user_id" : localStorage.getItem('userId'),
          "task_number" : this.task.data.task_number,
          "task_status" : this.task.data.task_status,
          "date" : new Date().toISOString().split('T')[0], // Format as YYYY-MM-DD
          "time": formattedTime,
        },
      };

      console.log(trackingData);

      try {
        await saveTrackedTime(trackingData);

        console.log('Tracked time saved successfully');
        eventBus.emit('show-sticky-note', 'Zeit wurde gespeichert' );
      } catch (error) {
        console.error('Failed to save tracked time:', error);
      }
    },


  },
};
</script>


<style scoped>
.task-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scanner-open {
  
  margin-bottom: 20px; 
}

#scanner-toggle-btn{
  margin-top: 10px;
  padding: 5px 15px;
  border: 0;
  background-color: var(--primary-color);
}

button {
  margin-bottom: 10px; /* Spacing around the toggle button */
}


</style>