<template>
  <div class="time-tracking">
    <!-- Timer Display -->
    <div class="timer">{{ formattedTime }}</div>

    <!-- Start and Stop Buttons -->
    <div class="button-group">
      <button :class="{ active: isRunning }" @click="onStart">START</button>
      <button :class="{ active: !isRunning && hasStarted }" @click="onStop" :disabled="!hasStarted">STOP</button>
    </div>
  </div>
</template>

<script>



export default {
  data() {
    return {
      timer: 0, // Timer in seconds
      intervalId: null, // Holds the setInterval ID for the timer
      isRunning: false, // Tracks if the timer is currently running
      hasStarted: false, // Tracks if the timer has started at least once
      startTime: null,
    };
  },
  computed: {
    formattedTime() {
      const hours = Math.floor(this.timer / 3600).toString().padStart(2, '0');
      const minutes = Math.floor((this.timer % 3600) / 60).toString().padStart(2, '0');
      const seconds = (this.timer % 60).toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.resumeTimer(); // Resume the timer if it was running
  },
  methods: {
    onStart() {
      if (this.isRunning) return;
      
      this.isRunning = true;
      this.hasStarted = true;

      // Set start time in localStorage if not already set
      if (!localStorage.getItem('timerStartTime')) {
        const currentTime = Date.now();
        localStorage.setItem('timerStartTime', currentTime);
        this.startTime = currentTime;
      } else {
        this.startTime = parseInt(localStorage.getItem('timerStartTime'));
      }
      // Start the timer
      this.intervalId = setInterval(this.updateTimer, 1000);
    },
    onStop() {
      if (!this.isRunning) return;

      this.isRunning = false;
      clearInterval(this.intervalId);
      this.intervalId = null;
      // Emit the elapsed time to TaskPage to save it
      this.$emit('save-time', this.timer );

      this.timer = 0;
      this.hasStarted = false;
      localStorage.removeItem('timerStartTime');

    },
    
    updateTimer() {
      const currentTime = Date.now();
      this.timer = Math.floor((currentTime - this.startTime) / 1000);
    },
    resumeTimer() {
      const savedStartTime = localStorage.getItem('timerStartTime');
      if (savedStartTime) {
        this.isRunning = true;
        this.hasStarted = true;
        this.startTime = parseInt(savedStartTime);
        this.intervalId = setInterval(this.updateTimer, 1000);
        this.updateTimer(); // Update immediately in case of resumed session
      }
    },
  },
  beforeUnmount() {
    if (this.isRunning) {
      clearInterval(this.intervalId);
    }
  },
  props: {
    task: {
      type: Object,
      default: null, // Set default to null for handling absence of data
    },
  },
};
</script>

<style scoped>
.time-tracking {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 20px 20px 0 0; /* Rounded corners only on top */
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%; /* Full width */
  padding-bottom: 40px; /* 40px space at the bottom */
}

.timer {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px; /* 40px gap between buttons */
}

button {
  width: 80%; /* 80% width for buttons */
  padding: 15px 25px; /* Adjusted padding */
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase; /* All caps for button titles */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--accent-color);
  color: white;
}

button.active {
  background-color: var(--button-color); /* Green color for active button */
  color: white;
}
</style>
