<template>
    <div>
      <input
        type="text"
        v-model="searchTerm"
        @input="onInput"
        placeholder="Auftragsnummer eingeben"
        id="search-input"
      />
      <ul v-if="showSuggestions && filteredTasks.length">
        <li v-for="task in filteredTasks" :key="task" @click="selectTask(task)">
          {{ task }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted } from "vue";
  import { getTaskList } from "../api/apiService";
  
  export default {
    name: "TaskSearch",
    setup(_, { emit }) {
      const searchTerm = ref("");
      const showSuggestions = ref(false);
      const taskNumbers = ref([]);
  
      // Fetch the task list on component mount
      onMounted(async () => {
        try {
          taskNumbers.value = await getTaskList();
        } catch (error) {
          console.error("Error fetching task list:", error);
        }
      });
  
      // Filter the tasks based on input
      const filteredTasks = computed(() => {
        if (searchTerm.value.length < 2) {
          return [];
        }
        const term = searchTerm.value.toLowerCase();
        return taskNumbers.value.filter((task) =>
          task.toLowerCase().includes(term)
        );
      });
  
      const onInput = () => {
        showSuggestions.value = searchTerm.value.length >= 2;
      };
  
      const selectTask = (task) => {
        searchTerm.value = task;
        showSuggestions.value = false;
        
         // Emit the selected task number to the parent
        emit("task-selected", task);

      };
  
      return {
        searchTerm,
        showSuggestions,
        filteredTasks,
        onInput,
        selectTask,
      };
    },
  };
  </script>
  
  <style scoped>
  ul {
    list-style-type: none;
    padding: 0;
    border: 1px solid #ccc;
    margin-top: 4px;
  }
  li {
    padding: 8px;
    cursor: pointer;
  }
  li:hover {
    background-color: #f0f0f0;
  }

  #search-input{
    padding: 10px 5px;
    width: 250px;
    border-radius: 10px;
    border: 1px #ccc ;
    background-color: var(--primary-color);
  
  }
  
  #search-btn{
    padding: 10px 15px;
    border: 0;
    background-color: var(--primary-color);
  
  }

  </style>
  